<template>

  <div class="container grid px-6 mx-auto dark:bg-gray-900 mi-div-especifico">
    <loading :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage">
    </loading>
    <h2 class="my-6 text-2xl font-semibold text-left text-gray-700 dark:text-gray-200">Template Accounts</h2>
    <div class="mb-2 grid grid-cols-1 gap-4 items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800" >
    <div class="w-auto rounded-lg shadow-xs">
        <div class="grid grid-cols-2 gap-1">
								<div class="items-center text-sm w-full">
									<Multiselect 
										v-model="group" 
										tag-placeholder="Add" 
										placeholder="Grupo" 
										label="name" track-by="code" 
										:options="objGroup" 
										:multiple="false" 
										:taggable="true" 
										@tag="addTag"
									></Multiselect>
								</div>
              </div>
              </div>
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
            <th class="px-2 py-3">
              <button
              v-on:click="getAccountTemplatesByGroup()"
              title="Buscar"
              class="px-2 py-2 font-medium leading-5 text-white transition-colors duration-150 bg-blue-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
              >
              <i class="mr-2 icon-arrows-cw" aria-hidden="true">Buscar</i>
            </button>
            <button
            style="margin: 10px"
              v-on:click="copyCompanyAccountsFromTemplate()"
              title="Buscar"
              class="px-2 py-2 font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"
              >
              <i class="mr-2 icon-arrows-cw" aria-hidden="true">Copiar Plantilla</i>
            </button>   
            <div>
    <button @click="mostrarNotificacion()">Mostrar notificación</button>
  </div>   
          </th>
        </tr>
      </thead>
    </table>
  </div>
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-2 py-3 text-center">Id</th>
              <th class="px-2 py-3 text-center">Codigo</th>
              <th class="px-2 py-3 text-center">Cuenta</th>
              <th class="px-2 py-3 text-center">Grupo</th>
              <th class="px-2 py-3 text-center">Nivel</th>
              <th class="px-2 py-3 text-center">Profundidad</th>
              <th class="px-2 py-3 text-center">Naturaleza</th>
              <th class="px-2 py-3 text-center">Credito</th>
              <th class="px-2 py-3 text-center">Debito</th>
              <th class="px-2 py-3 text-center">Saldo</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800" id="htp">
            <tr v-for="(item, index) in objTemplates" :key="index" class="text-gray-700 dark:text-gray-400">
              <td class="px-4 py-3 text-xs text-left">
                {{ item.id }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.accountCode }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.accountName }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.accountGroup }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.accountLevel }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.depth }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.accountNature }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.credit }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.debit }}
              </td>
              <td class="px-4 py-3 text-xs text-left">
                {{ item.balance }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="grid w-full grid-cols-3 gap-4 overflow-x-auto">
        <div></div>
        <div></div>
        
      </div>
    </div>

  
</template>

<script>
import Axios from "axios";
import { mapState, Store } from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect'
import { jsPDF } from "jspdf";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "ContentTemplateAccounts",
  components: {
    Loading,
    Multiselect,
  },
  props: {},
  data() {
    return {
      URL: process.env.VUE_APP_URL_ACC,
      objGroup: [],
      objTemplates: [],
      group:'',    
      date: "",
      activet: true,
      company_id: "",
      currentDate: moment().format('YYYY-MM-DD'),
      isLoading: false,
			fullPage: true,
    };
  },
  computed: {
    ...mapState(["user", "year", "access_token"]),
  },
  mounted() {
    this.notificationsWindows();
    this.getAccountTemplates();
  },

  methods: {
    notificationsWindows(){
      if ('Notification' in window) {
        Notification.requestPermission()
      }
    },

    mostrarNotificacion() {
      if (Notification.permission === 'granted') {
        new Notification('Título de la notificación', {
          body: 'Contenido de la notificación',
          icon: 'ruta/de/la/imagen.png',
          sound: 'Sound.mp3'
        })
      }
    },

    onCancel() {
      console.log('User cancelled the loader.')
    },

    async getAccountTemplates() {
      this.isLoading = true
				setTimeout(() => {
					this.isLoading = false
				}, 2000)
      const URL = `${this.URL}showAccountTemplates`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
        },
      };
      let params = {
        company_id: this.comapny_id,
      };
        let data = await Axios.post(URL, params, fetchConfig);
        let res = data.data.data;
        console.log('res')
        console.log(res)
        if (this.activet === true) {
          this.objGroup = res.templates.map(item=> {
							return { 
                name: item.template_group, 
                code: item.template_group,
              }		
					})
          console.log('this.objGroup')
          console.log(this.objGroup)
        }
        this.isLoading = false
        },
        
        async getAccountTemplatesByGroup() {
          this.isLoading = true
          setTimeout(() => {
            this.isLoading = false
          }, 2000)
          const URL = `${this.URL}showAccountTemplateDetailsFlatMode`;
          const fetchConfig = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
            },
          };
          if(this?.group) {
            let params = {
              template_group: this.group,
            }
            let data = await Axios.post(URL, params, fetchConfig);
            this.objTemplates = data.data.data;
            console.log('this.objTemplates')
            console.log(this.objTemplates)
          }else{
            alert("Seleccione un grupo");
          }
          this.isLoading = false
        },
        
        async copyCompanyAccountsFromTemplate() {
          this.isLoading = true
          setTimeout(() => {
            this.isLoading = false
          }, 2000)
          const URL = `${this.URL}copyCompanyAccountsFromTemplate`;
          const fetchConfig = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
            },
          };
          if(this?.group){
            let params = {
              destination_company_id: this.user.companyId,
              template_group: this.group,
              company: this.user.companyId,
              year: this.year
            }
        let data = await Axios.post(URL, params, fetchConfig);
        let copyTemplate = data.data.data;
        console.log('copyTemplate')
        console.log(copyTemplate)
        if(copyTemplate?.error ){
          alert(copyTemplate.error);
        }
        if(copyTemplate?.message){
          alert(copyTemplate.message);
        }   
    }else{
            alert("Seleccione un grupo");
          }    
  },

    async hpdf(){
      // get the HTML source file path
      var element = document.getElementById('htp');
      var doc = new jsPDF("p", "pt", "letter");
      doc.html(element).then(()=>doc.save(`Balance de comprobación`));
		},
    
    FormatJSon(FilterData, JsonData) {
      return JsonData.map((v) => FilterData.map((j=>{return v[j];})))
    },

    formatNumber(number, separator = ",", symbol = "$", showSymbol = false) {
      number = parseFloat(number);
      number = number.toFixed(2);
      if (separator == ",") {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      } else {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1,$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      }
    },

    addTag (newTag) {			
                const tag = {	
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.transaction.push(tag)
            },
    
  },
  watch: {

  },
};
</script>

<style>

</style>
