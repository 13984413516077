<template>
	<!-- <div v-if="hasPermission"> -->
	<div >
		<ContentTemplateAccount />
	</div>
</template>

<script>
// @ is an alias to /src

// import Loans from '@/components/content/content-loans/ContentLoans.vue'
import ContentTemplateAccount from '@/components/content/content-acc/ContentTemplateAccount.vue'
import { getPermissions } from '../components/util/liblist'
export default {
	name: 'TemplateAccount',
	components: {
		ContentTemplateAccount,
	},
	data() {
		return {
			currentComponent: 'ContentTemplateAccounts',
			'hasPermission': false,
		}
	},
	mounted() {
		if (getPermissions('accounting.report.journal')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
